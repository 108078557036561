import React from 'react';


function MainSection({ setPlaylist }) {
    const albums = [
        {
          id: 1, title: 'Album 1', image: '/assets/images/album1.png',
          songs: [
            { title: 'Song 1', url: "/assets/uploads/a.mp3" , image:'/assets/images/album1.png' },
            { title: 'Song 2', url: "/assets/uploads/b.mp3" , image:'/assets/images/album2.png' },
            { title: 'Song 3', url: "/assets/uploads/c.mp3" ,image:'/assets/images/album1.png'}
          ]
        },
        {
          id: 2, title: 'Album 2', image: '/assets/images/album2.png',
          songs: [
            { title: 'Song 1', url: "/assets/uploads/a.mp3" , image:'/assets/images/album1.png' },
            { title: 'Song 2', url: "/assets/uploads/b.mp3" , image:'/assets/images/album2.png' },
            { title: 'Song 3', url: "/assets/uploads/c.mp3" ,image:'/assets/images/album1.png'}
          ]
        },
        
        {
            id: 3, title: 'Album 3', image: 'https://via.placeholder.com/150',
            songs: [
              { title: 'Song 1', url: "/assets/uploads/a.mp3" , image:'/assets/images/album1.png' },
              { title: 'Song 2', url: "/assets/uploads/b.mp3" , image:'/assets/images/album2.png' },
              { title: 'Song 3', url: "/assets/uploads/c.mp3" ,image:'/assets/images/album1.png'}
            ]
        },
          
        {
            id: 4, title: 'Album 4', image: 'https://via.placeholder.com/150',
            songs: [
              { title: 'Song 1', url: 'song1.mp3' },
              { title: 'Song 2', url: 'song2.mp3' }
            ]
          }
        // Add more albums similarly...
      ];
      

  const handleAlbumClick = (album) => {
    setPlaylist(album.songs); // Set the playlist to the selected album's songs
  };

  return (
    <div className="p-5 mt-16 bg-white dark:bg-gray-900 text-black dark:text-white ml-64">
      <h2 className="text-3xl mb-5">Albums</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
        {albums.map((album) => (
            
          <div
            key={album.id}
            className="p-4 text-left w-56 h-58 cursor-pointer"
            onClick={() => handleAlbumClick(album)} // Trigger playlist update
          >
            <img src={album.image} alt={album.title} className=" w-44 h-44 rounded" />
            <h3 className="text-lg">{album.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MainSection;
