import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faHeart, faCompactDisc, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

function Sidebar({ isOpen, closeSidebar }) {
  return (
    <div className="fixed left-0 top-0 h-screen w-64 bg-white dark:bg-gray-800 text-black dark:text-white p-5 shadow-md">
      <h1 className="text-2xl font-bold mb-5">Music Player</h1>
      <ul className="space-y-4">
        <li className="flex items-center">
          <FontAwesomeIcon icon={faMusic} className="mr-3" />
          <span>Explore</span>
        </li>
        <li className="flex items-center">
          <FontAwesomeIcon icon={faHeart} className="mr-3" />
          <span>Favorites</span>
        </li>
        <li className="flex items-center">
          <FontAwesomeIcon icon={faCompactDisc} className="mr-3" />
          <span>Albums</span>
        </li>
        <li className="flex items-center">
          <FontAwesomeIcon icon={faPlayCircle} className="mr-3" />
          <span>Singles</span>
        </li>
      </ul>

      <hr className="my-6 border-gray-300 dark:border-gray-600" />

      <ul className="space-y-4">
        <li>Made for You</li>
        <li>Recently Played</li>
        <li>Find by Country</li>
        <li>Artists</li>
        <li>Playlists</li>
      </ul>

      <div className="mt-auto">
        <h2 className="text-xl mb-4">Advertisement</h2>
        <div className="bg-gray-200 dark:bg-gray-700 p-4 rounded">Ad Space</div>
      </div>
    </div>
  );
}

export default Sidebar;
