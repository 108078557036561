import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import MainSection from './components/MainSection';
import Footer from './components/Footer';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [playlist, setPlaylist] = useState([]);

  return (
    <div className={`${darkMode ? 'dark' : ''}`}>
      <div className="flex h-screen bg-white dark:bg-black">
        {/* Sidebar: Fixed on the left */}
        <Sidebar />

        <div className="flex-1 flex flex-col">
          {/* Header: Fixed at the top */}
          <Header toggleDarkMode={toggleDarkMode}  sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar}/>

          {/* Main Section: Scrollable content */}
          <div className="flex-1 overflow-y-auto">
            <MainSection setPlaylist={setPlaylist} />
          </div>

          {/* Footer: Fixed at the bottom */}
          <Footer playlist={playlist} />
        </div>
      </div>
    </div>
  );
}

export default App;
