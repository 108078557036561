import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

function Header({ toggleDarkMode }) {
  const [darkModeEnabled, setDarkModeEnabled] = React.useState(false);

  // Handle dark mode toggle
  const handleToggle = () => {
    setDarkModeEnabled(!darkModeEnabled);
    toggleDarkMode();
  };

  return (
    <header className="fixed top-0 left-64 right-0 z-10 bg-white dark:bg-gray-900 p-4 flex justify-between items-center shadow-md">
     <div className="flex-1 flex justify-center">
        <input
          type="text"
          placeholder="Search..."
          className="p-1 rounded-full bg-white dark:bg-gray-800 text-black dark:text-white shadow-md border-2 border-transparent focus:border-blue-400 focus:outline-none transition duration-300 ease-in-out"
        />
      </div>

      <div className="flex items-center space-x-4">
        <button className="text-black dark:text-white">
          <FontAwesomeIcon icon={faBell} />
        </button>
        <button onClick={handleToggle} className="text-black dark:text-white w-10 h-8">
          {darkModeEnabled ? (
            <FontAwesomeIcon icon={faSun} />
          ) : (
            <FontAwesomeIcon icon={faMoon} />
          )}
        </button>
        <img
          src="/assets/images/icon.png"
          alt="User"
          className="w-10 h-8 rounded-full"
        />
      </div>
    </header>
  );
}

export default Header;
