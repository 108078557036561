import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faPlay, faPause, faForward, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

function Footer({ playlist }) {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  // Set the current song's URL and update the audio element
  useEffect(() => {
    if (audioRef.current && playlist.length > 0) {
      const audioUrl = playlist[currentSongIndex]?.url;

      if (typeof audioUrl === 'string') {
        audioRef.current.src = audioUrl;

        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      } else {
        console.error('Invalid audio URL:', audioUrl);
      }
    }
  }, [currentSongIndex, playlist, isPlaying]);

  // Update current time as the song plays
  useEffect(() => {
    const audioElement = audioRef.current;

    const updateTime = () => {
      setCurrentTime(audioElement.currentTime);
    };

    const updateDuration = () => {
      setDuration(audioElement.duration);
    };

    if (audioElement) {
      audioElement.addEventListener('timeupdate', updateTime);
      audioElement.addEventListener('loadedmetadata', updateDuration);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', updateTime);
        audioElement.removeEventListener('loadedmetadata', updateDuration);
      }
    };
  }, []);

  // Handle play/pause toggle
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  // Handle next song
  const handleNextSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % playlist.length);
  };

  // Handle previous song
  const handlePreviousSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex === 0 ? playlist.length - 1 : prevIndex - 1));
  };

  // Convert time in seconds to a readable format (minutes:seconds)
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <footer className="fixed bottom-0 left-64 right-0 bg-white dark:bg-gray-900 p-4 flex items-center justify-between shadow-md">
      <div className="flex items-center space-x-4">
        {playlist[currentSongIndex]?.image && (
          <img
            src={playlist[currentSongIndex].image}
            alt="Song"
            className="w-16 h-16 rounded"
          />
        )}
        <FontAwesomeIcon
          icon={faBackward}
          className="text-black dark:text-white text-2xl cursor-pointer"
          onClick={handlePreviousSong}
        />
        <FontAwesomeIcon
          icon={isPlaying ? faPause : faPlay}
          className="text-black dark:text-white text-2xl cursor-pointer"
          onClick={handlePlayPause}
        />
        <FontAwesomeIcon
          icon={faForward}
          className="text-black dark:text-white text-2xl cursor-pointer"
          onClick={handleNextSong}
        />
      </div>

      <div className="flex-1 px-4">
        <audio ref={audioRef} />
        <p>{playlist[currentSongIndex]?.title || "No song playing"}</p>
        <input
          type="range"
          className="w-full"
          min="0"
          max={duration}
          value={currentTime}
          onChange={(e) => (audioRef.current.currentTime = e.target.value)}
        />
        <div className="flex justify-between text-black dark:text-white text-sm">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <FontAwesomeIcon icon={faVolumeUp} className="text-black dark:text-white text-xl" />
        <input
          type="range"
          className="w-24"
          min="0"
          max="1"
          step="0.01"
          onChange={(e) => (audioRef.current.volume = e.target.value)}
        />
      </div>
    </footer>
  );
}

// Exporting the Footer component
export default Footer;
